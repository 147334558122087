<template lang="pug">
    section#fiquePorDentro
        Editor(:contents="editor")
        ul
            li(v-for="box in boxes")
                a(:href="box.buttonLink")
                    .img
                        img(:src="box.image").image
                    h3 {{ box.title }}
                    p {{ box.text }}
                    span {{ box.buttonName }}
</template>

<script>
import { props } from '@/mixins/component'
import Editor from '@sections/Pagina/Editor/Editor'

export default {
    name: "section-communications",
	props,
    components: {
        Editor,
    },
	computed: {
		boxes() {
			return this.contents.content.boxes.map(box => ({
				...box,
				image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${box.image}`
			}))
		},
		editor() {
            return {content: this.contents.content.editor}
        },
	},
}
</script>

<style lang="stylus" scoped src="./CommunicationsSection.styl"></style>
