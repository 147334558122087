<template lang="pug">
	section#editor
		.wrapper
			.editor
				component(:is="item.component", :contents="item.contents", v-for="item, index in mappedComponents", :key="`${item.type}-${index}`")
</template>

<script>
import { props, textComponents as components, textDictionary } from '@/mixins/component'

export default {
	name: "section-editor",
	props,
	components,
	computed: {
		parsed() {
			return JSON.parse(this.contents.content)
		},
		mappedComponents() {
			return this.parsed.map(item => {
				return { component: this.$options.components[textDictionary[item.type]], contents: item, type: item.type }
			})
        },
	},
}
</script>

<style lang="stylus" scoped src="./Editor.styl"></style>
