import { render, staticRenderFns } from "./CommunicationsSection.vue?vue&type=template&id=4e82ef84&scoped=true&lang=pug&"
import script from "./CommunicationsSection.vue?vue&type=script&lang=js&"
export * from "./CommunicationsSection.vue?vue&type=script&lang=js&"
import style0 from "./CommunicationsSection.styl?vue&type=style&index=0&id=4e82ef84&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e82ef84",
  null
  
)

export default component.exports